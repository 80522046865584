@use 'src/src/app/scss/abstracts' as *;

@keyframes fade-in {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.ws-custom-overlay {
  -webkit-tap-highlight-color: transparent;
  position: fixed;
  z-index: $z-index-modal;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden auto;
  animation: fade-in $transition-fast;
  overscroll-behavior: contain;
  cursor: pointer;
  background-color: rgb(0 0 0 / 60%);

  &--side {
    background-color: rgb(0 0 0 / 50%);
  }

  &--image {
  }

  &--full {
    background-color: transparent;
  }
}

.ws-custom-modal {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 100%;
  pointer-events: none;

  &--side {
    align-items: flex-start;
  }

  &--right {
    align-items: flex-end;
  }

  &--image {
  }

  &--full {
  }
}

@keyframes zoom-in {
  from {
    opacity: 0;
    transform: scale(0.9);
  }

  to {
    opacity: 1;
    transform: scale(1);
  }
}

.modal {
  max-width: 100%;
  padding: 15px;

  &__inner {
    position: relative;
    width: 100%;
    max-width: 1024px;
    height: auto;
    min-height: 825px;
    padding: 35px 20px 45px;
    animation: zoom-in $transition-fast;
    background: $color-base-black-200;
    box-shadow: 0 4px 34px 0 rgb(118 69 255 / 50%);
    cursor: auto;
    pointer-events: all;
    border-radius: $border-radius-s;

    @include mq($until: md) {
      min-height: 600px;
    }

    @include mq($until: sm) {
      min-height: 577px;
      padding: 15px 15px 25px;
    }
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 35px;

    @include mq($until: md) {
      padding-bottom: 20px;
    }
  }

  &__title {
    color: $color-base-purple-200;
    flex: 1 1 auto;
    min-width: 0;

    > .title {
      padding-right: 5px;
    }

    // font-size: 48px;
    // font-style: normal;
    // font-weight: $font-weight-xl;
    // line-height: 100%; /* 48px */
    // letter-spacing: -0.96px;
  }

  &__button-close {
    flex: 0 0 auto;
    position: relative;
    z-index: $z-index-modal;
    transition: opacity $transition-fast;
    margin-left: 15px;
    margin-right: -10px;
    margin-top: -65px;

    @include mq($until: md) {
      margin-right: -16px;
    }

    @include mq($until: sm) {
      margin-right: -11px;
      margin-top: -26px;
    }

    button {
      @include reset-btn;

      display: flex;
      align-items: center;
      justify-content: center;
      width: 40px;
      height: 40px;
      transform: rotate(45deg);
      color: $color-base-gray-500;
      transition: color $transition-fast;

      &:hover {
        color: $color-base-purple-200;
      }

      &:active {
        color: $color-base-gray-500;
      }

      svg {
        stroke: currentColor;
      }
    }
  }

  &__content {
    padding-top: 0;
    width: 625px;
    max-width: 100%;
  }
}
